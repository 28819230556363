import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { Typography, Theme, Button, Box, FormLabel } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { getMyPersonalProfileInfo } from "./action";
import Utils from "../../utils";
import { LocalImages } from "../../utils/images";
// import DateTimePicker from '../../components/formsUi/dateTimePicker';
import moment from "moment";
import { getFileType } from "../../utils/commonFunctions";
import PreviewImg from "../../components/previewImg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiFormLabel-asterisk": {
        color: "red",
        // height: "16px",
        // width: "16px",
        // backgroundColor: "#ee4040",
        // borderRadius: "50%",
        // paddingLeft: "7px",
        // marginLeft: "4px",
      },
    },
    button: {
      color: "var(--white)",
      backgroundColor: "var(--light-green)",
    },
    heading: {
      "&.MuiTypography-root": {
        font: `normal ${theme.typography.fontWeightBold} ${theme.spacing(2)} Roboto, sans-serif`,
      },
    },
    uploadedImage: {
      width: "140px",
      height: "120px",
      boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
      borderRadius: "8px",
      cursor: "pointer",
      // width: "auto",
      // height: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      // width: "auto",
      // height: "auto",
      // maxWidth: '100%',
      // maxHeight: '100%'
    },
    uploadedImageCover: {
      width: "140px",
      height: "120px",
      boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
      borderRadius: "8px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    uploadedImageDocNotPresent: {
      width: "140px",
      height: "120px",
      // width: "auto",
      // height: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
      borderRadius: "8px",
      cursor: "pointer",
      // border: '1px solid red',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    primaryText: {
      "&.MuiTypography-root": {
        font: `normal ${theme.typography.fontWeightBold} ${theme.spacing(2)} Roboto, sans-serif`,
        fontSize: 14,
        margin: "revert",
      },
    },
    secondryText: {
      "&.MuiTypography-root": {
        font: `normal ${theme.typography.fontWeightLight} ${theme.spacing(2)} Roboto, sans-serif`,
        fontSize: 14,
        color: "var(--light-gray)",
      },
    },
  })
);

export default function ProfileData() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState("");

  useEffect(() => {
    dispatch(getMyPersonalProfileInfo());
  }, [dispatch]);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const {
    avatar,
    email,
    phone_number,
    phone_code,
    pharmacy_name,
    city_name,
    physical_address,
    license_of_pharmacy,
    pin_certificate,
    business_permit,
    practice_certificate,
    admin_name,
    cr_12,
    tax_compliance,
    business_permit_expiry,
    license_of_pharmacy_expiry,
    practice_certificate_expiry,
    tax_compliance_expiry,
    name_prefix,
  } = useSelector((state: any) => state.MyProfilePersonalInfoReducer);

  const setImageIconOrUrl = (url: string): string => {
    if (getFileType(url) === "doc") {
      return LocalImages.DocIcon;
    }
    if (getFileType(url) === "pdf") {
      return LocalImages.PdfIcon;
    }
    if (getFileType(url) === "xlsx") {
      return LocalImages.ExcelIcon;
    }
    return Utils.constants.API_URL + url;
  };

  const checkFileTypeForPreview = (url: string): boolean => {
    if (getFileType(url) === "doc" || getFileType(url) === "pdf" || getFileType(url) === "xlsx") {
      return true;
    }
    return false;
  };
  var todayDate = moment().format("YYYY MM DD");
  const handleEditProfile = () => {
    navigate(`${Utils.routes.myProfileEdit}`);
    dispatch(getMyPersonalProfileInfo());
  };
  return (
    <>
      <div className={classes.root}>
        <div className="page-personal-info">
          <Grid container spacing={2} sx={{ flexGrow: 1, alignItems: "stretch" }}>
            <Grid item xs={12} sm={12} md={12}>
              <div className="bg-white">
                <div className="space-between mb-10">
                  <Typography className={classes.heading}>Profile Information</Typography>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ color: "var(--white)", fontWeight: "bold" }}
                    onClick={() => handleEditProfile()}
                  >
                    Edit
                  </Button>
                </div>

                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  {avatar ? (
                    <Grid item xs={12} sm={3} md={3}>
                      <h4>Profile Picture</h4>
                      <Box sx={{ display: "flex" }}>
                        <img
                          onClick={() => {
                            setPreviewImgUrl(Utils.constants.API_URL + avatar);
                            handleOpenPreview();
                          }}
                          alt="Remy Sharp"
                          src={Utils.constants.API_URL + avatar}
                          // src={LocalImages.Aiims}
                          style={{
                            width: 75,
                            height: 80,
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                        />
                        {!license_of_pharmacy ||
                        !pin_certificate ||
                        !business_permit ||
                        !practice_certificate ||
                        !cr_12 ||
                        !tax_compliance ||
                        moment(todayDate).isSameOrAfter(practice_certificate_expiry) ||
                        moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry) ||
                        moment(todayDate).isSameOrAfter(business_permit_expiry) ||
                        moment(todayDate).isSameOrAfter(tax_compliance_expiry)
                          ? // <span className="dotProfile"></span>
                            ""
                          : ""}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={3} md={3}>
                      <h4 className="mb-10">Profile Picture</h4>
                      <Box sx={{ display: "flex" }}>
                        <img
                          src={LocalImages.DefaultImage}
                          alt="NoImage found"
                          width="150px"
                          height="100px"
                          style={{ borderRadius: "50%" }}
                        />
                        <span className="dot"></span>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={9} md={9}>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <h4>Pharmacy Name</h4>
                        <p>{pharmacy_name}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <h4>Pharmacy City</h4>
                        <p>{city_name}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <h4>Email Id</h4>
                        <p>{email}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <h4>Address</h4>
                        <p>{physical_address}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <h4>Mobile Number</h4>
                        <p>{"+" + phone_code + phone_number}</p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <h4>Pharmacy Admin Name</h4>
                        <p>{name_prefix + " " + admin_name}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <div className="bg-white">
                <Typography sx={{ marginBottom: "10px" }} className={classes.heading}>
                  Documents
                </Typography>
                <Grid container spacing={2}>
                  {practice_certificate ? (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10">
                        Practice Certificate of Pharmacist in Charge
                        {!practice_certificate || moment(todayDate).isSameOrAfter(practice_certificate_expiry) ? (
                          <span className="dotProfile"></span>
                        ) : (
                          ""
                        )}
                      </h4>

                      {checkFileTypeForPreview(practice_certificate) ? (
                        <a
                          href={Utils.constants.API_URL + practice_certificate}
                          target="_blank"
                          download
                          className={classes.uploadedImageCover}
                        >
                          <img
                            className={classes.uploadedImage}
                            src={setImageIconOrUrl(practice_certificate)}
                            alt="DocImg"
                          />
                        </a>
                      ) : (
                        <img
                          onClick={() => {
                            setPreviewImgUrl(setImageIconOrUrl(practice_certificate));
                            handleOpenPreview();
                          }}
                          className={
                            moment(todayDate).isSameOrAfter(practice_certificate_expiry)
                              ? classes.uploadedImageDocNotPresent
                              : classes.uploadedImage
                          }
                          src={setImageIconOrUrl(practice_certificate)}
                          // src={Utils.constants.API_URL + practice_certificate}
                          alt="DocImg"
                        />
                      )}

                      <Box sx={{ fontSize: "14px", mt: 0.6 }}>
                        <b>Expiry Date: </b> {moment(practice_certificate_expiry).format("DD-MM-YYYY")}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10border">
                        <FormLabel
                          sx={{
                            fontSize: 16,
                            marginBottom: "10px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          Practice Certificate of Pharmacist in Charge
                          {!practice_certificate || moment(todayDate).isSameOrAfter(practice_certificate_expiry) ? (
                            <span className="dotProfile"></span>
                          ) : (
                            ""
                          )}
                        </FormLabel>
                      </h4>
                      <img
                        src={LocalImages.DefaultImage}
                        alt="NoImage found"
                        className={classes.uploadedImageDocNotPresent}
                      />
                      <Box sx={{ fontSize: "14px", mt: 0.4 }}>
                        <b>Expiry Date: </b> {moment(practice_certificate_expiry).format("DD-MM-YYYY")}
                      </Box>
                    </Grid>
                  )}

                  {license_of_pharmacy ? (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10">
                        License of Pharmacy
                        {!license_of_pharmacy || moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry) ? (
                          <span className="dotProfile"></span>
                        ) : (
                          ""
                        )}
                      </h4>
                      {checkFileTypeForPreview(license_of_pharmacy) ? (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <a href={Utils.constants.API_URL + license_of_pharmacy} target="_blank" download>
                            <img
                              className={classes.uploadedImage}
                              src={setImageIconOrUrl(license_of_pharmacy)}
                              alt="DocImg"
                            />
                          </a>
                          {/* <a
                          href={Utils.constants.API_URL + license_of_pharmacy}
                          target="_blank"
                          download
                        >
                          View file
                        </a> */}
                        </Box>
                      ) : (
                        <img
                          onClick={() => {
                            setPreviewImgUrl(setImageIconOrUrl(license_of_pharmacy));
                            handleOpenPreview();
                          }}
                          src={setImageIconOrUrl(license_of_pharmacy)}
                          alt="DocImg"
                          className={
                            moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry)
                              ? classes.uploadedImageDocNotPresent
                              : classes.uploadedImage
                          }
                        />
                      )}

                      <Box sx={{ fontSize: "14px", mt: 0.4 }}>
                        <b>Expiry Date: </b> {moment(license_of_pharmacy_expiry).format("DD-MM-YYYY")}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10border">
                        <FormLabel
                          sx={{
                            fontSize: 16,
                            marginBottom: "10px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          License of Pharmacy
                          {!license_of_pharmacy || moment(todayDate).isSameOrAfter(license_of_pharmacy_expiry) ? (
                            <span className="dotProfile"></span>
                          ) : (
                            ""
                          )}
                        </FormLabel>
                      </h4>
                      <img src={LocalImages.DefaultImage} className={classes.uploadedImageDocNotPresent} />
                      <Box sx={{ fontSize: "14px", mt: 0.4 }}>
                        <b>Expiry Date: </b> {moment(license_of_pharmacy_expiry).format("DD-MM-YYYY")}
                      </Box>
                    </Grid>
                  )}

                  {business_permit ? (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10">
                        Business Permit
                        {!business_permit || moment(todayDate).isSameOrAfter(business_permit_expiry) ? (
                          <span className="dotProfile"></span>
                        ) : (
                          ""
                        )}
                      </h4>
                      {checkFileTypeForPreview(business_permit) ? (
                        <a href={Utils.constants.API_URL + business_permit} target="_blank" download>
                          <img
                            className={classes.uploadedImage}
                            src={setImageIconOrUrl(business_permit)}
                            alt="DocImg"
                          />
                        </a>
                      ) : (
                        <img
                          onClick={() => {
                            setPreviewImgUrl(setImageIconOrUrl(business_permit));
                            handleOpenPreview();
                          }}
                          src={setImageIconOrUrl(business_permit)}
                          alt="DocImg"
                          className={
                            moment(todayDate).isSameOrAfter(business_permit_expiry)
                              ? classes.uploadedImageDocNotPresent
                              : classes.uploadedImage
                          }
                        />
                      )}

                      <Box sx={{ fontSize: "14px", mt: 0.4 }}>
                        <b>Expiry Date: </b> {moment(business_permit_expiry).format("DD-MM-YYYY")}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10border">
                        <FormLabel
                          sx={{
                            fontSize: 16,
                            marginBottom: "10px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          Business Permit
                          {!business_permit || moment(todayDate).isSameOrAfter(business_permit_expiry) ? (
                            <span className="dotProfile"></span>
                          ) : (
                            ""
                          )}
                        </FormLabel>
                      </h4>
                      <img
                        src={LocalImages.DefaultImage}
                        alt="NoImage found"
                        className={classes.uploadedImageDocNotPresent}
                      />
                      <Box sx={{ fontSize: "14px", mt: 0.4 }}>
                        <b>Expiry Date: </b> {moment(business_permit_expiry).format("DD-MM-YYYY")}
                      </Box>
                    </Grid>
                  )}
                  {pin_certificate ? (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10">
                        Pin (Tax) Certificate
                        {!pin_certificate ? <span className="dotProfile"></span> : ""}
                      </h4>
                      {checkFileTypeForPreview(pin_certificate) ? (
                        <a href={Utils.constants.API_URL + pin_certificate} target="_blank" download>
                          <img
                            className={classes.uploadedImage}
                            src={setImageIconOrUrl(pin_certificate)}
                            alt="DocImg"
                          />
                        </a>
                      ) : (
                        <img
                          onClick={() => {
                            setPreviewImgUrl(setImageIconOrUrl(pin_certificate));
                            handleOpenPreview();
                          }}
                          src={setImageIconOrUrl(pin_certificate)}
                          alt="DocImg"
                          className={classes.uploadedImage}
                        />
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10border">
                        <FormLabel
                          sx={{
                            fontSize: 16,
                            marginBottom: "10px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          Pin (Tax) Certificate
                          {!pin_certificate ? <span className="dotProfile"></span> : ""}
                        </FormLabel>
                      </h4>
                      <img
                        src={LocalImages.DefaultImage}
                        alt="NoImage found"
                        className={classes.uploadedImageDocNotPresent}
                      />
                    </Grid>
                  )}
                  {cr_12 ? (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10">
                        CR12
                        {!cr_12 ? <span className="dotProfile"></span> : ""}
                      </h4>
                      {checkFileTypeForPreview(cr_12) ? (
                        <a href={Utils.constants.API_URL + cr_12} target="_blank" download>
                          <img className={classes.uploadedImage} src={setImageIconOrUrl(cr_12)} alt="DocImg" />
                        </a>
                      ) : (
                        <img
                          onClick={() => {
                            setPreviewImgUrl(setImageIconOrUrl(cr_12));
                            handleOpenPreview();
                          }}
                          src={setImageIconOrUrl(cr_12)}
                          alt="DocImg"
                          className={classes.uploadedImage}
                        />
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10border">
                        <FormLabel
                          sx={{
                            fontSize: 16,
                            marginBottom: "10px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          CR12
                          {!cr_12 ? <span className="dotProfile"></span> : ""}
                        </FormLabel>
                      </h4>
                      <img
                        src={LocalImages.DefaultImage}
                        alt="NoImage found"
                        className={classes.uploadedImageDocNotPresent}
                      />
                    </Grid>
                  )}
                  {tax_compliance ? (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10">
                        Tax Compliance
                        {!tax_compliance || moment(todayDate).isSameOrAfter(tax_compliance_expiry) ? (
                          <span className="dotProfile"></span>
                        ) : (
                          ""
                        )}
                      </h4>
                      {checkFileTypeForPreview(tax_compliance) ? (
                        <a href={Utils.constants.API_URL + tax_compliance} target="_blank" download>
                          <img className={classes.uploadedImage} src={setImageIconOrUrl(tax_compliance)} alt="DocImg" />
                        </a>
                      ) : (
                        <img
                          onClick={() => {
                            setPreviewImgUrl(setImageIconOrUrl(tax_compliance));
                            handleOpenPreview();
                          }}
                          src={setImageIconOrUrl(tax_compliance)}
                          alt="DocImg"
                          className={
                            moment(todayDate).isSameOrAfter(tax_compliance_expiry)
                              ? classes.uploadedImageDocNotPresent
                              : classes.uploadedImage
                          }
                        />
                      )}

                      <Box sx={{ fontSize: "14px", mt: 0.4 }}>
                        <b>Expiry Date: </b> {moment(tax_compliance_expiry).format("DD-MM-YYYY")}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4}>
                      <h4 className="mb-10border">
                        <FormLabel
                          sx={{
                            fontSize: 16,
                            marginBottom: "10px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          Tax Certificate
                          {!tax_compliance || moment(todayDate).isSameOrAfter(tax_compliance_expiry) ? (
                            <span className="dotProfile"></span>
                          ) : (
                            ""
                          )}
                        </FormLabel>
                      </h4>
                      <img
                        src={LocalImages.DefaultImage}
                        alt="NoImage found"
                        className={classes.uploadedImageDocNotPresent}
                      />
                      <Box sx={{ fontSize: "14px", mt: 0.4 }}>
                        <b>Expiry Date: </b> {moment(tax_compliance_expiry).format("DD-MM-YYYY")}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <PreviewImg open={openPreview} handleClose={handleClosePreview} image={previewImgUrl} />
      </div>
    </>
  );
}
